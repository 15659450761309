import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import * as firebase from 'firebase';
import 'bootstrap/dist/css/bootstrap.min.css';

import ManaSagaApp from './ManaSaga/ManaSaga';
import { Spinner } from 'reactstrap';

const App = () => {
  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyDX92YM46EPLtybtz6f75UMolajQOOwoSk",
    authDomain: "manasaga-ac4f6.firebaseapp.com",
    databaseURL: "https://manasaga-ac4f6.firebaseio.com",
    projectId: "manasaga-ac4f6",
    storageBucket: "manasaga-ac4f6.appspot.com",
    messagingSenderId: "97261789553",
    appId: "1:97261789553:web:0964a31ec3fcd98eee96d0",
    measurementId: "G-KRFFFH74YL"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  console.log("Firebase", firebase);

  return (
    <BrowserRouter>
      {/* { attemptedFirstLogin ? <Route path="/" render={ManaSagaApp} /> : <Spinner />} */}
      <Route path="/" render={ManaSagaApp} />
    </BrowserRouter>
  );
}

export default App;
