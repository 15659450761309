import * as React from 'react';
import { Route, Redirect, Switch} from 'react-router-dom';
import * as Strap from 'reactstrap';
import firebase from 'firebase';

import './ManaSaga.scss';

import Nav from './components/nav/nav';
import Login from './Screens/Login/Login';
import Profile from './Screens/Profile/Profile';
import Home from './Screens/Home/Home';
import Collection from './Screens/Collection/Collection';

export default () => {
  // const [loginAttempted, setLoginAttempted] = React.useState(false);

  const currentUser = firebase.auth().currentUser;
  const requestLogin = <Redirect to="/Login" />

  return <>
    <Nav />
    <Strap.Container id="PrimaryContainer">
      <Switch>
        <Route path="/Home" component={Home} />
        <Route path="/StackSimulator" component={undefined} />
        <Route path="/Search" component={undefined} />
        
        <Route path="/Login" component={Login} />

        {/* { !currentUser ? requestLogin : undefined } */}

        <Route path="/Profile" component={Profile} />
        <Route path="/Settings" component={undefined} />
        <Route path="/Collection" component={Collection} />
        <Route path="/MyTags" component={undefined} />
        <Route path="/Decks" component={undefined} />

        <Redirect exact from="/" to="/Home" />
      </Switch>
    </Strap.Container>
  </>
}