import * as React from 'react';
import * as scryfall from 'scryfall';
import {
  Nav,
  Navbar,
  NavItem,
  NavLink

} from 'reactstrap';
import Symbol from './../ScryFallSymbol/ScryFallSymbol';

import './CardFilterToolBar.scss';


export interface iProps {

}

export default (props : iProps) => {
  const [filters, setFilters] = React.useState<{[id: string] : boolean}>({});

  const toggleFilter = (e : React.MouseEvent<any, MouseEvent>) => {
    let newFilters = {...filters};
    if(newFilters[e.currentTarget.name]) {
      newFilters[e.currentTarget.name] = !filters[e.currentTarget.name];
    } else {
      newFilters[e.currentTarget.name] = true;
    }
    setFilters(newFilters);
  }

  return <div className="CardFilterToolbar">
  <Navbar>
    <Nav pills>
      <NavItem>
         <NavLink href="#" name="{W}" onClick={toggleFilter} active={filters["{W}"]}><Symbol symbol="{W}" /></NavLink>
      </NavItem>

      <NavItem>
        <NavLink href="#" name="{U}" onClick={toggleFilter} active={filters["{U}"]}><Symbol symbol="{U}" /></NavLink>
      </NavItem>

      <NavItem>
        <NavLink href="#" name="{B}" onClick={toggleFilter} active={filters["{B}"]}><Symbol symbol="{B}" /></NavLink>
      </NavItem>

      <NavItem>
        <NavLink href="#" name="{R}" onClick={toggleFilter} active={filters["{R}"]}><Symbol symbol="{R}" /></NavLink>
      </NavItem>

      <NavItem>
        <NavLink href="#" name="{G}" onClick={toggleFilter} active={filters["{G}"]}><Symbol symbol="{G}" /></NavLink>
      </NavItem>
    </Nav>
  </Navbar>

  <Navbar>
    <Nav pills>
      <NavItem>
        <NavLink href="#" name="rarity:common" onClick={toggleFilter} active={filters["rarity:common"]}><span style={{color: "rgb(100,100,100)"}}>Common</span></NavLink>
      </NavItem>

      <NavItem>
        <NavLink href="#" name="rarity:uncommon" onClick={toggleFilter} active={filters["rarity:uncommon"]}><span style={{color: "rgb(185,220,235)"}}>Uncommon</span></NavLink>
      </NavItem>

      <NavItem>
        <NavLink href="#" name="rarity:rare" onClick={toggleFilter} active={filters["rarity:rare"]}><span style={{color: "rgb(230,205,140)"}}>Rare</span></NavLink>
      </NavItem>

      <NavItem>
        <NavLink href="#" name="rarity:mythic" onClick={toggleFilter} active={filters["rarity:mythic"]}><span style={{color: "rgb(245,145,5)"}}>Mythic Rare</span></NavLink>
      </NavItem>
    </Nav>
  </Navbar>
  </div>
}