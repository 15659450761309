import * as React from 'react';
import { Spinner } from 'reactstrap';

import requestSymbology from './../../ScryFallApi/client/symbology/request_symbology';
import cardSymbol from '../../ScryFallApi/definitions/symbology/card_symbol';
import symbology from '../../ScryFallApi/definitions/symbology/symbology';

import './ScryFallSymbol.scss';

export interface iProps {
  symbol ?: cardSymbol["symbol"];
  english ?: cardSymbol["english"];
  size ?: "sm" | "md" | "lg";
  padding ?: string;
  customClassNames ?: string;
}

export default (props: iProps) => {
  const [SymbologyData, setSymbologyData] = React.useState<undefined | symbology>();
  const [renderedValue, setRenderedValue] = React.useState<JSX.Element>(<Spinner size={props.size} type="grow" alt="Loading Icon" />);

  let Class_Size = props.size ? props.size : 'sm';

  React.useEffect(()=>{
    requestSymbology().then( symbolData => {
      setSymbologyData(symbolData);
    });
  }, []);

  React.useEffect(()=>{
    SymbologyData?.data.forEach( Symbol => {
      if((Symbol.english === props.english) || (Symbol.symbol === props.symbol)) {
        setRenderedValue(<img className={Class_Size} src={Symbol.svg_uri} alt={Symbol.english} />);
      }
    })
  }, [SymbologyData]);

  return <span style={{padding : props.padding}} className={`ScryFallSymbol ${props.customClassNames}`}>
    {renderedValue}
  </span>
}