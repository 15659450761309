import * as React from 'react';
import * as Strap from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Firebase from 'firebase';
import { useHistory } from 'react-router-dom';

import Symbol from './../ScryFallSymbol/ScryFallSymbol';

import './style.scss';

export default () => {
  const routerHistory = useHistory();
  const [navOpen, setNavOpen] = React.useState(false);
  const [user, setUser] = React.useState<Firebase.User | null>(Firebase.auth().currentUser);

  React.useEffect(()=>{
    Firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    })
  }, []);

  const logout = () => {
    Firebase.auth().signOut();
  }

  const toggleNavBar = () => setNavOpen(!navOpen);

  const userMenu = () => <>
    <Strap.NavItem>
      <Strap.UncontrolledDropdown nav inNavBar>
        <Strap.DropdownToggle nav caret>My Collection</Strap.DropdownToggle>
        <Strap.DropdownMenu right>
          <Strap.DropdownItem>
            <Link to="Collection">
              Entire Collection  
            </Link>
          </Strap.DropdownItem>
          <Strap.DropdownItem>
            <Link to="MyTags">
              Tag Manager
            </Link>
          </Strap.DropdownItem>
          <Strap.DropdownItem divider/>
          <Strap.DropdownItem>
            <Link to="Decks">
              Decks
            </Link>
          </Strap.DropdownItem>
        </Strap.DropdownMenu>
      </Strap.UncontrolledDropdown>
    </Strap.NavItem>

    <Strap.NavItem>
      <Strap.UncontrolledDropdown nav inNavBar>
        <Strap.DropdownToggle nav caret>
          {user?.email?.split("@")[0]}
        </Strap.DropdownToggle>
        <Strap.DropdownMenu right>
          <Strap.DropdownItem>
            <Link to="/Profile">
              Profile
            </Link>
          </Strap.DropdownItem>
          <Strap.DropdownItem>
            <Link to="/Settings">
              Settings
            </Link>
          </Strap.DropdownItem>
          <Strap.DropdownItem divider/>
          <Strap.DropdownItem onClick={logout}>
            Logout
          </Strap.DropdownItem>
        </Strap.DropdownMenu>
      </Strap.UncontrolledDropdown>
    </Strap.NavItem>
  </>

  const userNull = () => <>
    <Strap.NavItem>
      <Link className="nav-link" to="/Login">Login</Link>
    </Strap.NavItem>
  </>

  return <div id="Navigation">
      <Strap.Navbar color="dark" expand="md" dark>

        <Strap.Nav navbar>
          <Strap.NavbarBrand>

            <Link className="nav-link" to="/Home">
              ManaSaga
              <Symbol padding="5px" english="one white mana" />
              <Symbol padding="5px" english="one blue mana" />
              <Symbol padding="5px" english="one black mana" />
              <Symbol padding="5px" english="one red mana" />
              <Symbol padding="5px" english="one green mana" />
            </Link>
          </Strap.NavbarBrand>
        </Strap.Nav>

        <Strap.NavbarToggler onClick={toggleNavBar} />
        <Strap.Collapse isOpen={navOpen} navbar>


        <Strap.Nav className="ml-auto" navbar>
          <Strap.NavItem>
            <Link className="nav-link" to="/StackSimulator">Stack Simulator</Link>
          </Strap.NavItem>

          <Strap.NavItem>
            <Link className="nav-link" to="/Search">Advanced Card Search</Link>
          </Strap.NavItem>

        { user ? userMenu() : userNull() }
        </Strap.Nav>

        </Strap.Collapse>

      </Strap.Navbar>
    </div>
}