import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faAlien, faCreditCardBlank } from '@fortawesome/pro-duotone-svg-icons';

export interface iProps {
  selectMethod : (x : "search" | "setId") => void;
}

export default (props : iProps) => {

  return <div className="selectEntryMethod">
  <div className="square" onClick={() => props.selectMethod("search")}>
    <div className="icon">
      <FontAwesomeIcon icon={faSearch} />
    </div>
    <div className="label">
      Search
    </div>
  </div>

  <div className="square" onClick={() => props.selectMethod("setId")}>
    <div className="icon">
      <FontAwesomeIcon icon={faCreditCardBlank} />
    </div>
    <div className="label">
      Set ID
    </div>
  </div>

  <div className="square">
    <div className="icon">
      <FontAwesomeIcon icon={faAlien} />
    </div>
    <div className="label">
      Foo Bar
    </div>
  </div>
</div>
}