import * as React from 'react';
import * as scryfall from 'scryfall';
import { ScryfallCard } from 'scryfall/build/ScryfallCard';
import { Modal, ModalBody, ModalHeader, Button} from 'reactstrap';

import './PlayingCard.scss';

export interface iProps {

}

export default (props : iProps) => {
  const [CardData, setCardData] = React.useState<ScryfallCard | undefined>(undefined)
  const [ModalOpen, setModalOpen] = React.useState(false);

  const toggleModal = () => setModalOpen(!ModalOpen);


  const fetchRandomCard = async () => {
    let card = await scryfall.randomCard();
    setCardData(card);
  }

  React.useEffect(()=>{
    fetchRandomCard();
  }, []);

  return <span className="playingCard">
    <img onClick={toggleModal} src={CardData?.image_uris?.border_crop} />

    <Modal className="modal-content-dark" size="xl" toggle={toggleModal} isOpen={ModalOpen}>
      <ModalHeader>{CardData?.name}</ModalHeader>
      <ModalBody>
        <Button color='warning'>Remove From Collection</Button>
        <pre>
          {CardData ? JSON.stringify(CardData, undefined, 2) : "Fetching Card"}
        </pre>
      </ModalBody>
    </Modal>
  </span>
}