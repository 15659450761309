import CachedItem from './../../definitions/CachedItem';

export default (cacheKey : string, cacheLife : number, obj : object) => {
  window.localStorage.removeItem(cacheKey);

  let tempCacheObj: CachedItem<any> = {
    storedDate : Date.now(),
    expiresDate : Date.now() + cacheLife,
    data : obj
  }

  window.localStorage.setItem(cacheKey, JSON.stringify(tempCacheObj));
}