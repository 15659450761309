import * as React from 'react';
import { Input, Label, Card, CardBody, CardHeader, CardImg, CardTitle, CardImgOverlay } from 'reactstrap';

export default () => {

  return <div className="Search">
    <Label for="search">Search By Title</Label>
    <Input id="search" type="text" />
    <div className="CardGridResults">
      <Card inverse>
        <CardImg src="https://img.scryfall.com/cards/art_crop/front/a/9/a906f718-635b-4d51-8896-530c52b260f7.jpg?1562791246" />
        <CardImgOverlay className="showOnHover">
          <CardTitle>StormCrag Elemental</CardTitle>
        </CardImgOverlay>
      </Card>

      <Card inverse>
        <CardImg src="https://img.scryfall.com/cards/art_crop/front/4/b/4b7e33a8-765b-4909-a5c6-5fe8f8774a51.jpg?1576384631" />
        <CardImgOverlay className="showOnHover">
          <CardTitle>Mirrorwing Dragon</CardTitle>
        </CardImgOverlay>
      </Card>

      <Card inverse>
        <CardImg src="https://img.scryfall.com/cards/art_crop/front/6/8/6830e76d-7d38-4f6e-8ab7-abd9ac3fb0d9.jpg?1582244616" />
        <CardImgOverlay className="showOnHover">
          <CardTitle>Nicol Bolas, Dragon-God</CardTitle>
        </CardImgOverlay>
      </Card>

      <Card inverse>
        <CardImg src="https://img.scryfall.com/cards/art_crop/front/0/1/0104b5b3-9376-4ad7-9a77-3e564e9c42e6.jpg?1555040636" />
        <CardImgOverlay className="showOnHover">
          <CardTitle>Ghalta, Primal Hunger</CardTitle>
        </CardImgOverlay>
      </Card>

      {/* <Card inverse>
        <CardImg src="" />
        <CardImgOverlay className="showOnHover">
          <CardTitle></CardTitle>
        </CardImgOverlay>
      </Card> */}

      

    </div>
  </div>
}