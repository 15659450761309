import * as React from 'react';
import * as Strap from 'reactstrap';
import * as Firebase from 'firebase';

import './Login.scss';

export default () => {
  const [processing, setProcessing] = React.useState(false);
  const [passwordToolTip, setPasswordToolTip] = React.useState(false);
  const [credentials, setCredentials] = React.useState({email : "", password : ""});
  const togglePasswordToolTip = () => setPasswordToolTip(!passwordToolTip);

  const setCredential = (cred : "email" | "password", newVal : string) => {
    const newCred = {...credentials};
    if(cred === "email") newCred.email = newVal;
    if(cred === "password") newCred.password = newVal;
    setCredentials(newCred);
  }

  const createAccount = async () => {
    setProcessing(true);
    let AuthResponse = undefined;
    
    try {
      AuthResponse = await Firebase.auth().createUserWithEmailAndPassword(credentials.email, credentials.password);
    } catch(error) {
      console.log("New User Signup Error", error);
    }

    setProcessing(false);
  }

  const login = async () => {
    setProcessing(true);
    let AuthResponse = undefined;

    try {
      AuthResponse = await Firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password);
    } catch(error) {
      console.log("User Login Error", error);
    }
    
    setProcessing(false);
  }

  return <div id="Screen_Login">
    <Strap.Row>
      <Strap.Col 
        xs="12" 
        sm="12" 
        md={{size: 6, offset: 3}} 
        lg={{size: 6, offset: 3}} 
        xl={{size: 4, offset: 4}}>
        <Strap.Form>
            <Strap.Row form>
              <Strap.Col>
                <Strap.FormGroup>
                  <Strap.Label for="email">Email</Strap.Label>
                  <Strap.Input id="email" disabled={processing} onChange={e => setCredential("email", e.target.value)} />
                </Strap.FormGroup>
              </Strap.Col>
            </Strap.Row>

            <Strap.Row form>
              <Strap.Col>
                <Strap.FormGroup>
                  <Strap.Label for="password">Password</Strap.Label>
                  <Strap.Input type="password" id="password" disabled={processing} onChange={e => setCredential("password", e.target.value)} onBlur={()=>setPasswordToolTip(false)} />
                </Strap.FormGroup>
              </Strap.Col>
            </Strap.Row>

            <Strap.Row>
              <Strap.Col sm="6">
                <Strap.Button onClick={createAccount} disabled={processing} block>New Account</Strap.Button>
              </Strap.Col>
              <Strap.Col sm="6">
                <Strap.Button color="success" onClick={login} disabled={processing} block>Login</Strap.Button>
              </Strap.Col>
            </Strap.Row>
        </Strap.Form>
      {processing ? 
        <Strap.Row>
          <Strap.Col xs={{offset : 5, size: 2}}>
            <Strap.Spinner type="grow" />
          </Strap.Col>
        </Strap.Row> : undefined}
      </Strap.Col>
    </Strap.Row>

      <Strap.Popover 
        target="password" 
        placement="right"
        color="warning"
        isOpen={passwordToolTip} 
        toggle={togglePasswordToolTip}>
        <Strap.PopoverHeader>Password Requirements</Strap.PopoverHeader>
        <Strap.PopoverBody>
          <ul>
            <li>Minimum 6 Characters</li>
            <li>Minimum 1 Letter</li>
            <li>Minimum 1 Number</li>
            <li>Minimum 1 Special Character</li>
            <li>Foo</li>
            <li>Bar</li>
            <li>Lorem</li>
            <li>Ipsum</li>
          </ul>
        </Strap.PopoverBody>

      </Strap.Popover>
    </div>
}