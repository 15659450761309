import * as React from 'react';
import {
  Row, 
  Col,
  NavLink,
} from 'reactstrap';

import './Collection.scss';

import PlayingCard from './../../components/PlayingCard/PlayingCard';
import CardFilterToolBar from './../../components/CardFilterToolBar/CardFilterToolBar';
import AddCard from './../../components/AddCard/AddCard';
import AddToCollectionModal from './../../components/AddToCollectionModal/AddToCollectionModal';

export default () => {
  const [addCardIsOpen, setAddCardIsOpen] = React.useState(false);

  return <div className="Screen_Collection">
  {/* <AddCard addCardIsOpen={addCardIsOpen} toggler={setAddCardIsOpen} /> */}
  <AddToCollectionModal isOpen={addCardIsOpen} toggler={setAddCardIsOpen} />
  <Row className="Row_Heading">
    <Col>
      <h1>Collection</h1>
    </Col>
    <Col className="right">
      <NavLink href="#" onClick={()=>{setAddCardIsOpen(!addCardIsOpen)}}>Add to Collection</NavLink>
    </Col>
  </Row>
  <Row className="Row_Filter">
    <CardFilterToolBar />
  </Row>
  <Row className="Row_Collection">
    <PlayingCard />
  </Row>
  </div>
}