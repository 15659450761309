import CachedItem from './../../definitions/CachedItem';

/**
 * Test Cache returns a numerical value that represents the status of the cache available
 * @returns -1 = Cache is Missing / Not Present
 * @return 0 = Cache is present but stale; you should refetch the data
 * @return 1 = Cache is present and healthy; Network Requests are not needed
 */
export default async (cacheKey : string) : Promise<-1 | 0 | 1> => {
  let cacheValue = window.localStorage.getItem(cacheKey);

  if(!cacheValue) { // Truthy return null, ergo null or undefined
    return -1;
  };

  if(cacheValue) {
    let cacheObject: CachedItem<any> = JSON.parse(cacheValue);
    let currentDate = Date.now();
    let cacheExpirationDate = cacheObject.expiresDate;

    if(currentDate > cacheExpirationDate) {
      return 0;
    } else {
      return 1;
    }
  }

  // Fail Safe; if we make it here then we don't know what the cache is and should just fetch new data
 return -1;
}