import * as React from 'react';
import * as Strap from 'reactstrap';
import * as Firebase from 'firebase';

import './Profile.scss';

export default () => {

  const [ComPhoto, setComPhoto] = React.useState('');

  React.useEffect(()=>{
    fetch("https://api.scryfall.com/cards/random?q=t:planeswalker").then(fetchResponse => {
      fetchResponse.json().then(data => {
        // console.log(data);
        // setComPhoto(data.image_uris.art_crop);
        setComPhoto(data.image_uris.png);
      })
    })
  }, [])

  return <div id="Screen_Profile">
    <Strap.Row>
      <Strap.Col sm="3"><img className="profilePicture" src={ComPhoto} /></Strap.Col>
      <Strap.Col>Email : {Firebase.auth().currentUser?.email}</Strap.Col>
    </Strap.Row>
  </div>
}