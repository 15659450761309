import * as React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faAlien, faCreditCardBlank } from '@fortawesome/pro-duotone-svg-icons';

import SelectEntryMethod from './SelectEntryMethod';
import Search from './Search';

import Card from './../../ScryFallApi/definitions/cards/card';

import './AddToCollectionModal.scss';

export interface iProps {
  isOpen : boolean;
  toggler : (x: boolean) => void;
}

export default (props: iProps) => {
  const [cardsToAdd, setCardsToAdd] = React.useState<Card[]>([]);
  const [entryMethod, setEntryMethod] = React.useState<'selectEntryMethod' | 'search' | 'setId'>('selectEntryMethod');

  const getModalBodyContent = () => {
    switch(entryMethod) {
      case("selectEntryMethod") : return <SelectEntryMethod selectMethod={setEntryMethod} />;
      case("search") : return <Search />
    }
  }

  return<>
    <Modal id="AddToCollectionModal" size="xl" isOpen={props.isOpen} toggle={()=>{props.toggler(!props.isOpen)}}>
      <ModalHeader>
        Add to your Collection
      </ModalHeader>
      <ModalBody>
        { getModalBodyContent() }
      </ModalBody>
      <ModalFooter>
        { entryMethod !== "selectEntryMethod" ? <Button className="BackToSelectEntryMethod mr-auto" onClick={()=>setEntryMethod("selectEntryMethod")}>Back to Entry Selection</Button> : undefined}
        <Button>Done</Button>
      </ModalFooter>
    </Modal>
  </>
}